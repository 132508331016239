import * as React from 'react';
const classnames = require('classnames');

import './XingIcon.scss';

/**
 * Based on https://iconmonstr.com/xing-4-svg/
 */
const XingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const classes = classnames('XingIcon', props.className);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
      className={classes}
    >
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-5.911 14.996l2.4-4.233-1.614-2.767h2.881l1.601 2.759-2.401 4.241h-2.867zm10.85 4.004h-2.848l-3.105-5.639 4.708-8.361h2.877l-4.707 8.361 3.075 5.639z" />
    </svg>
  );
};

export default XingIcon;
