import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './IntroSectionAboutUs.scss';
import Slant from '../Slant';
const dataPoints = require('../../assets/img/frogo-about-us-intro-image-data-points.svg');

export const INTRO_TEXT =
  'Effizientes datengetriebenes Marketing soll nicht nur Unternehmen ' +
  'mit eigenen Data-Science-Teams vorbehalten sein. Mit FROGO AD ' +
  'wollen wir zeitaufwendige Marketing&shy;prozesse ' +
  'auto&shy;matisieren und verborgenes Optimierungs&shy;potential ' +
  'aufdecken. Dazu bietet FROGO AD nutzer&shy;freundliche ' +
  'Technologie&shy;lösungen zum effizienten Management und zur ' +
  'Verbesserung der Kampagnen-Performance. ';

const IntroSectionAboutUs = () => {
  return (
    <section className="IntroSectionAboutUs text-white">
      <Container>
        <Row className="pt-2">
          <Col xs={12} md={4}>
            <p className="lead text-left text-md-right">
              <img
                src={dataPoints}
                alt="Analyse-Icon"
                className="img-fluid IntroSectionAboutUs-data-point-image"
              />
            </p>
          </Col>
          <Col xs={12} md={7}>
            <h6 className="mb-0">Über Uns</h6>
            <h2 className="mb-0">Unsere Mission</h2>
            <p
              className="lead IntroSectionAboutUs-text"
              dangerouslySetInnerHTML={{ __html: INTRO_TEXT }}
            />
          </Col>
        </Row>
      </Container>
      <Slant style={{ color: '#f5f8fa' }} />
    </section>
  );
};

export default IntroSectionAboutUs;
