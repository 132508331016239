import * as React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import SwappingNav from '../components/SwappingNav';
import TopNavbar from '../components/TopNavBar';
import IntroSectionAboutUs, {
  INTRO_TEXT
} from '../components/AboutUs/IntroSectionAboutUs';
import TeamSectionAboutUs from '../components/AboutUs/TeamSectionAboutUs';
import TrustSectionAboutUs from '../components/AboutUs/TrustSectionAboutUs';

import Footer from '../components/Footer';
import IeWarning from '../components/IeWarning';

const metaDescription = INTRO_TEXT;

export default class extends React.Component<{}, {}> {
  public render() {
    return (
      <Layout>
        <Helmet>
          <title>Über Uns</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <SwappingNav
          scrollInHeight={15}
          initialNavbar={<TopNavbar transparent={true} />}
          swappedNavbar={<TopNavbar />}
        />
        <main>
          <IntroSectionAboutUs />
          <TeamSectionAboutUs />
          <TrustSectionAboutUs />
          <Footer />
        </main>
        <IeWarning />
      </Layout>
    );
  }
}
