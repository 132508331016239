import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './TeamSectionAboutUs.scss';

import EmailIcon from './EmailIcon';
import TwitterIcon from './TwitterIcon';
import LinkedInIcon from './LinkedInIcon';
import XingIcon from './XingIcon';

const karimProfilePicture = require('../../assets/img/team/Karim.jpg');
const ericProfilePicture = require('../../assets/img/team/Eric.jpg');
const davidProfilePicture = require('../../assets/img/team/David.jpg');

const TeamSectionAboutUs = () => {
  return (
    <section className="TeamSectionAboutUs">
      <Container className="TeamSectionAboutUs-content">
        <Row className="pt-2">
          <Col xs={12}>
            <h5 className="text-uppercase text-center TeamSectionAboutUs-headline">
              Das Gründer-Team
            </h5>
          </Col>
        </Row>
        <Row className="pt-2 px-sm-5">
          <Col xs={12} md={4} className="pb-4">
            <img
              src={karimProfilePicture}
              className="img-fluid mb-2 TeamSectionAboutUs-profile-pictures"
              alt="Profilbild: Karim"
            />
            <p className="lead text-left text-sm-left TeamSectionAboutUs-headline-text">
              <b>Karim Omar</b>
              <br />
              <p className="TeamSectionAboutUs-content">
                <b>Development & Quality Assurance</b>
                <br />
                Co-Founder & Managing Director
                <p className="mt-3">
                  <a href="mailto:karim.omar@frogo-ad.com">
                    <EmailIcon className="TeamSectionAboutUs-team-social" />
                  </a>
                  <a
                    href="https://twitter.com/kriimomar"
                    target="_blank"
                    rel="noopener"
                    className="pl-2"
                  >
                    <TwitterIcon className="TeamSectionAboutUs-team-social" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/karim-o/"
                    target="_blank"
                    rel="noopener"
                    className="pl-2"
                  >
                    <LinkedInIcon className="TeamSectionAboutUs-team-social" />
                  </a>
                </p>
              </p>
            </p>
          </Col>
          <Col xs={12} md={4} className="pb-4">
            <img
              src={ericProfilePicture}
              className="img-fluid mb-2 TeamSectionAboutUs-profile-pictures"
              alt="Profilbild: Karim"
            />
            <p className="lead text-left text-sm-left TeamSectionAboutUs-headline-text">
              <b>Eric Schweihofer</b>
              <br />
              <p className="TeamSectionAboutUs-content">
                <b>Product, BI & AdTech</b>
                <br />
                Co-Founder & Managing Director
                <p className="mt-3">
                  <a href="mailto:eric.schweihofer@frogo-ad.com">
                    <EmailIcon className="TeamSectionAboutUs-team-social" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/eric-schweihofer-64b654112"
                    target="_blank"
                    rel="noopener"
                    className="pl-2"
                  >
                    <LinkedInIcon className="TeamSectionAboutUs-team-social" />
                  </a>
                  <a
                    href="https://www.xing.com/profile/Eric_Schweihofer/"
                    target="_blank"
                    rel="noopener"
                    className="pl-2"
                  >
                    <XingIcon className="TeamSectionAboutUs-team-social" />
                  </a>{' '}
                </p>
              </p>
            </p>
          </Col>
          <Col xs={12} md={4}>
            <img
              src={davidProfilePicture}
              className="img-fluid mb-2 TeamSectionAboutUs-profile-pictures"
              alt="Profilbild: Karim"
            />
            <p className="lead text-left text-sm-left TeamSectionAboutUs-headline-text">
              <b>David Seydel</b>
              <br />
              <p className="TeamSectionAboutUs-content">
                <b>Design & UI/UX, Business Administration</b>
                <br />
                Co-Founder & Managing Director
                <p className="mt-3">
                  <a href="mailto:david.seydel@frogo-ad.com">
                    <EmailIcon className="TeamSectionAboutUs-team-social" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/david-seydel-20a5b8107"
                    target="_blank"
                    rel="noopener"
                    className="pl-2"
                  >
                    <LinkedInIcon className="TeamSectionAboutUs-team-social" />
                  </a>
                  <a
                    href="https://www.xing.com/profile/David_Seydel"
                    target="_blank"
                    rel="noopener"
                    className="pl-2"
                  >
                    <XingIcon className="TeamSectionAboutUs-team-social" />
                  </a>
                </p>
              </p>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TeamSectionAboutUs;
