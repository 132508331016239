import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './TrustSectionAboutUs.scss';

const fuLogo = require('../../assets/img/logos/fu-logo.svg');
const dmexcoLogo = require('../../assets/img/logos/dmexco-logo.svg');
const existLogo = require('../../assets/img//logos/exist-logo.svg');
const profundLogo = require('../../assets/img//logos/profund-logo.svg');
const entrepreneurShipLogo = require('../../assets/img//logos/entrepreneurship-hub-logo.svg');

const TrustSectionAboutUs = () => {
  return (
    <section className="TrustSectionAboutUs">
      <Container>
        <div className="pt-2 px-sm-5 lead text-left text-md-left TrustSectionAboutUs-text">
          <p>
            FROGO AD wird unterstützt <br />
            bzw. ist bekannt durch
          </p>
        </div>
        <div className="px-sm-5 d-flex justify-content-between flex-nowrap flex-column flex-sm-row ">
          <img
            src={fuLogo}
            alt="FU-Logo"
            className="mx-3 mr-md-3 mt-4 mt-md-3 mt-lg-4 TrustSectionAboutUs-logo"
          />
          <img
            src={entrepreneurShipLogo}
            alt="Entrepreneurship-Hub-Logo"
            className="mx-3 mt-4 mt-md-3 mt-lg-4 TrustSectionAboutUs-logo"
          />
          <img
            src={dmexcoLogo}
            alt="Dmexco-Logo"
            className="mx-3 mt-4 mt-md-3 mt-lg-4 py-3 TrustSectionAboutUs-logo"
          />
          <img
            src={existLogo}
            alt="Exist-Logo"
            className="mx-3 mt-4 mt-md-3 mt-lg-4 py-3 TrustSectionAboutUs-logo"
          />
          <img
            src={profundLogo}
            alt="Profund-Logo"
            className="mx-3 ml-md-3 mt-4 mt-md-3 mt-lg-4 py-3 TrustSectionAboutUs-logo"
          />
        </div>
      </Container>
    </section>
  );
};

export default TrustSectionAboutUs;
